import React from "react";
import WhatWeDo from "./WhatWeDo";
import { Element } from "react-scroll";
import Contact from "../Contact/Contact";
import Footer from "../footer/Footer";
import Servicess from "./Servicess";
import BlogList from "../blog/BlogList";

const Services = () => {
  return (
    <>
      <div className="bg-[#201A1E]">
        <WhatWeDo />
        <Servicess />
        <div className=" bg-[#FBFEFF] px-5 py-28 border-b-[1px] border-[#201A1E]">
          <h1 className="text-sm px-12 text-[#] font-bold">Newsletter</h1>
          <p className="text-[#201A1E] text-5xl font-light px-12">
            Insights That Drive Success
          </p>
          <BlogList />
        </div>
        <div className="sticky top-0 z-30">
          <Element name="contact">
            <Contact />
          </Element>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Services;
