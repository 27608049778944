import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import blogs from "../../lib/Blogs";

const BlogDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const blog = blogs.find((b) => b.id.toString() === id);

  if (!blog) {
    return (
      <div className="font-bold text-center text-red-500">Blog not found!</div>
    );
  }

  return (
    <div className="bg-[#201A1E]">
      <div className="max-w-4xl p-6 mx-auto">
        {/* Back Icon */}
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-[#9e9898] hover:text-[#d9d9d9] mb-4">
          <ChevronLeft size={20} />
          <span className="ml-1">Back</span>
        </button>

        {/* Blog Title */}
        <h1 className="text-3xl text-[#d9d9d9] font-bold mb-4">{blog.title}</h1>

        {/* Blog Date */}
        <p className="text-[#9e9898] mb-6">{blog.date}</p>

        {/* Main Blog Image */}
        <img
          src={blog.image}
          alt={blog.title}
          className="w-full h-auto mb-6 rounded-lg shadow-md"
        />

        {/* Blog Content */}
        <p className="text-lg text-[#9e9898] mb-8">{blog.content}</p>

        {/* Additional Sections with Images and Content */}
        {blog.additionalSections.map((section, index) => (
          <div key={index} className="mb-8">
            {/* Section Image */}
            <img
              src={section.image}
              alt={`Section ${index + 1}`}
              className="w-full h-auto mb-4 rounded-lg shadow-md "
            />

            {/* Section Content */}
            <p className="text-lg text-[#9e9898]">{section.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogDetail;
