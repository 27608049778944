import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import Landingpage from "./pages/Landingpage";
import BlogDetail from "./Components/blog/BlogDetail";

const router = createBrowserRouter([
  { path: "/", element: <Landingpage /> },
  { path: "/blog/:id", element: <BlogDetail /> },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
