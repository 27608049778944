import image1 from "../assets/pictures/blog/image1.jpeg";
import image2 from "../assets/pictures/blog/image2.png";
import image3 from "../assets/pictures/blog/image3.png";
import image4 from "../assets/pictures/blog/image4.png";
import image5 from "../assets/pictures/blog/image5.png";
import image6 from "../assets/pictures/blog/image6.png";
import image7 from "../assets/pictures/blog/image7.png";
import image8 from "../assets/pictures/blog/image8.png";
import image9 from "../assets/pictures/blog/image9.png";
import image10 from "../assets/pictures/blog/image10.png";
import image11 from "../assets/pictures/blog/image11.png";
import image12 from "../assets/pictures/blog/image12.png";
import image13 from "../assets/pictures/blog/image13.png";
import image14 from "../assets/pictures/blog/image14.png";

const blogs = [
  {
    id: 1,
    title: "September's Unforgettable Moments",
    description:
      "September was nothing short of incredible! From Cape Coast to Accra, the month was packed with unforgettable events, vibrant celebrations, and exciting brand activations......",
    date: "1 Oct, 2024",
    image: image1,
    content:
      "September was nothing short of incredible! From Cape Coast to Accra, the month was packed with unforgettable events, vibrant celebrations, and exciting brand activations. Let’s take a look at the month’s highlights!",
    additionalSections: [
      {
        image: image2,
        content:
          "The month started with the colorful Fetu Festival in Cape Coast, a celebration of the rich culture of the Fante people. Festival-goers enjoyed lively parades and traditional dancing, with Club Beer adding a refreshing twist at their branded beer tents. Ebony Condoms made an important statement with their booth promoting sexual health, while Belaqua kept everyone hydrated with free water stations. GOIL powered the logistics, ensuring the festival’s smooth flow from the parade to transportation. Social media buzzed withvibrant moments, making it a celebration to remember.",
      },
      {
        image: image3,
        content:
          "In Accra, the soulful Esther Smith Concert brought gospel music lovers together at Perez Dome for a night of spiritual connection. Ghana Tourism Authority took the opportunity to showcase Ghana’sbeauty with exclusive tourism packages, while Healthtech Ghana provided free health screenings to the crowd. Global Media Alliance broadcasted the event live, connecting audiences beyond the venue and amplifying its spiritual impact.",
      },
      {
        image: image4,
        content:
          "The Pulse Influencer Summit brought together digital creators and marketers for a day of learning and collaboration. Ghandour Cosmetics pampered attendees with beauty stations, while Twellium Ghana kept them refreshed with their signature drinks. Flora Tissues added a practical touch with branded products, and Top Choco offered sweet treats during breaks. It was an inspiring event, leaving influencers buzzing with new ideas for the digital space.",
      },
      {
        image: image5,
        content:
          "The elegant EMY Africa Pre-Awards Gala at Labadi Beach Hotel was a night of glamour, as industry leaders and influential personalities gathered for a prelude to the main awards. Access Bank took center stage with an exclusive VIP lounge, while Flora Tissues added class to the evening. Woodin brought style with a chic pop-up shop, and KGL Group showcased tech innovations, making it a perfect blend of elegance and technology.",
      },
      {
        image: image6,
        content:
          "The high-energy Accravaganza at Ghud Park lit up the city with electrifying performances from top Ghanaian artists. Boomplay created a music lovers’ paradise with interactive playlist booths,while Guinness kept the crowd engaged at their bold beer garden. Yango ensured smooth rides to and from the event and Voltic quenched the crowd’s thirst with free water bottles. It was a music festival that had everyone talking!",
      },
      {
        image: image7,
        content:
          "To close out the month, the All-White Party at Garage was the epitome of style and nightlife. Johnnie Walker added a touch of luxury with premium cocktails, tasting sessions, and limited-edition giveaways. The city’s trendsetters partied the night away, making it an unforgettable evening of music, fashion, and fun. September’s events were a true celebration of culture, music, and innovation. Each event came to life through incredible brand activations, ensuring memorable experiences at every turn. As we look forward to October, one thing is for sure—September set the bar high!! But guess what? October is gearing up to be just as exciting! Get ready for some electrifying events to partner such as the electrifying EDM festival, a mouthwatering Food Festival, TCL—an unmissable live music event that promises to bring the house down and many more. These events are the perfect platform for brands to connect with enthusiastic audiences, boost awareness, and drive meaningful interactions. The potential for brand sales, content creation and overall massive brand impact! Don’t miss out on being part of these iconic experiences. September sponsors saw tremendous engagement—now’s your chance to get in on the action for October! P.S. Which event was your favorite? We’d love to hear your highlights!",
      },
    ],
  },
  {
    id: 2,
    title: "The Thrilling Events Of October",
    description:
      "October was a jam-packed, thrilling month full with fantastic brand activations and life-changing events. Now let's explore the highlights.",
    date: "4 Nov, 2024",
    image: image8,
    content:
      "October was a jam-packed, thrilling month full with fantastic brand activations and life-changing events. Now let's explore the highlights.",
    additionalSections: [
      {
        image: image9,
        content: `This is Ghana’s biggest beach gathering which occured at the La Palm Royal beach this year, is held anually to enjoy the sun, and different versions of music. The aim of the event is to stimulate youth culture across Africa and to export Ghanaian musical culture beyond the shores. Over the years, the festival has also been displaying relevant lifestyle elements such as fashion, dance and games to give ravers a reason to be at the festival. October got off to an exciting start, catching the sun at tidal rave, an unforgettable night at the La Palm Royal beach.  Attendees witnessed incredible performances from their favourite artistes and dancers.Absa bank’s ignition market made it simple for ravers to buy snacks with their cashless system. Coca-cola kept provided incredible drinks to keep ravers refreshed and enabling them  to pair it with food purchases from their village.Johnnie Walker brought creativity allowing ravers to make their own cocktails with their drinks  to enjoy during the concert. Squads were brought closer to play games and have fun thanks to close up including their exclusive artiste village.Flame condoms utilising the event to encourage responsible sexual behaviour amongst ravers.`,
      },
      {
        image: image10,
        content:
          "The event was to launch the new brutal fruit drink which Accra brewery PLC made their new addition. This drink is originally from South Africa.The sparkling brutal fruit spritzer brunch at Savannah GH was a sophisticated and sweet affair as guests were treated to a variety of activities including artist performances from Poetra Asantewaa with an electrifying speech of the brutal fruit tale, Kaesa and Kidi. Guests were engaged in a pink carpet runway experience as well as a sip and paint session that  brought life to art. Brielle Cosmetics gave the girlies a simple yet lovely makeup in the touch-up booth at the Spritzer Saturday Brunch.",
      },
      {
        image: image11,
        content:
          "The pulse influencer awards is celebrated to highlight influencers and honor their groundbreaking impact in the creative space.The theme for this year was Futuristic Fashion: AI Glamour. This initiative is aimed primarily at engaging the influencer communities, highlighting the most consistent and impactful creators and ultimately also helping to strengthen the growth of digital media across Africa.The fourth edition of the pulse influencer to celebrate outstanding influencers and content creators brought together influencers, content creators and social media lovers for a night of glitz and glamour at the De-Icon events centre. Bolt Ghana provided cheap transportation tickets to the venue. With their new technology, Gwo Sevo innovated throughout the evening making it simple for content creators and influencers to monitise their work. Johnnie Walker and Sahara Solace serving their fantastic signature cocktails to guests. Twillium Ghana served attendees with their refreshing drinks and water to relieve attendees of their thirst. Q Bespoke Accra crafted exquisite backgrounds for the red carpet and to create memories. Ghandour cosmetics ad Flora tissues presented amazing gift packages to awardees of the night..",
      },
      {
        image: image12,
        content:
          "The Cross It Out concert  was the climax of the Cross It Out tour which was dedicated to empowering young women through education and early detection initiatives. The initiative aimed to eliminate the risks, fear and stigma associated with breast cancer especially among young women in high schools. Proceeds from concert were used to fund breast cancer awareness programs and treatment for those in need. The mx24 cross it out experience hosted by the beautiful twins, lalixlola  to raise awareness for  breast cancer was an exciting and educational experience. Peeva drinks giving out a refreshing bottle of any of their drinks for attendees who purchase a ticket. Life healthcare centre offering free hepatitis B and breast cancer screenings for attendees. Top chocolate advertising their brand-new chocolate-paste tube packaging. Mephlix foods offering snacks for attendees to purchase and munch on while enjoying beautiful performances from different artists. Mayekoo used this event as a platform to give out cash prizes as well as shopping vouchers for those who donate to support their project of improving communities. ",
      },
      {
        image: image13,
        content:
          "The EDM festival is an annual two day event that combines drinks, food, and music. It also showcases a mixture of contemporary and traditional music. There’s a variety of cultural and arts exhibitions. The event provides a world of finger licking good local and continental dishes. There are family-friendly interactive activities for every age group. The festival is strongly dedicated to providing superior event experiences tailored to attendees.It was all fun and games at the Eat Drink and Music festival which brought friends and families together at the ghud park behind  the Accra mall. Enjoy the atmosphere and stay invigorated with Pizzaman Chickenman's succulent, delectable, and flavourful meals. Koa natural provided their revitalising natural juices to quench thirst as you soak in the music.Yango providing convenient and secure transportation to the festival.Veetickets offering a seamless ticketing experience to be able to attend and take in the  the festival’s excitement. The FDA ensuring all delicacies and drinks are safe for consumption.Eye 360 ensuring tight security and safety throughout the event. Lime AR made navigation easy with a simple phone scan to access a navigation map to guide attendees to various spots. *981# games and *213# instant games engaging attendees in their fun mobile games to play and win exciting prizes.",
      },
      {
        image: image14,
        content:
          "The chop bar experience is a monthly celebration of the country’s rich culinary heritage. It features a diverse range of dishes from different parts of the country that reflect the unique cultural influences that have shaped Ghanaian cuisines over the years. The festival brings together local food lovers, food and beverage vendors and culinary enthusiasts from across the country to enjoy the best of Ghanaian foods and drinks.At the untamed empire, the pink affair with the chop bar experience was a wonderful and fulfilling way to close the month.Attendees enjoyed live football games while sipping a cool refreshing bottle of Guinness.Belpack enhanced on-the-go comfort.As guests savoured their meals, Verna mineral water kept them hydrated. P2 Events’ exquisite setup and decor gave guests a pleasant area to relax and eat.In addition to enjoying flavourful meals, club beer provided their refreshing bottles to offset the food. It was a mouthwatering event that left everyone feeling satisfied.",
      },
    ],
  },
];

export default blogs;
