import React from "react";
import { Link } from "react-router-dom";
import blogs from "../../lib/Blogs";

const BlogList = () => {
  return (
    <div className="grid w-full h-auto grid-cols-1 gap-4 px-12 py-6 md:grid-cols-2 lg:grid-cols-3 newsletter">
      {blogs.map((blog) => (
        <div key={blog.id} className="overflow-hidden border-none rounded-md">
          <img
            src={blog.image}
            alt={blog.title}
            className="w-full h-[236px] rounded-2xl"
          />
          <div className="flex flex-col items-start gap-6 ">
            <p className="text-[#3E3E3E] text-sm pt-4">{blog.date}</p>
            <h2 className="text-xl text-[#201A1E] font-['Epilogue']">
              {blog.title}
            </h2>
            {/* <p className="text-[#9e9898]">{blog.description}</p> */}
            <Link to={`/blog/${blog.id}`}>
              <button className="w-[130px] h-[45px] bg-[#201A1E] text-[#d9d9d9] hover:text-gray-500/75 border rounded-3xl py-1 px-4">
                Read More
              </button>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogList;
