import React, { useState } from "react";
import Logo from "../../assets/svg/logo.svg";
import { Link as ScrollLink } from "react-scroll";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="absolute top-0 left-0 right-0 z-80 bg-[#201A1ECC] font-epilogue h-[61px] backdrop-blur-sm">
      <div className="mx-auto flex h-16 max-w-screen-xl items-center gap-8 px-4 sm:px-6 lg:px-8">
        <div className="flex items-center">
          <a href="/">
            <img src={Logo} alt="Logo" className="h-[21] w-[111]" />
          </a>
        </div>

        <div className="flex md:hidden ml-auto">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            {isOpen ? (
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>

        <nav
          className={`md:flex md:flex-1 md:items-center md:justify-end w-full ${
            isOpen
              ? "block absolute top-[61px] bg-[#201A1ECC] backdrop-blur-sm left-0 p-2"
              : "hidden"
          }`}
        >
          <ul className="flex flex-col md:flex-row items-center justify-end gap-6 text-sm">
            <li>
              <ScrollLink
                to="services"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="text-[#E2E6E7] transition hover:text-gray-500/75 cursor-pointer"
              >
                Our Vibe
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="text-[#E2E6E7] transition hover:text-gray-500/75 cursor-pointer"
              >
                Let's Vibe
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="newsletter"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="text-[#E2E6E7] transition hover:text-gray-500/75 cursor-pointer"
              >
                Newsletter
              </ScrollLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
